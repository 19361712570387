#modelBlock {
   align-items: flex-end;
   background: var(--bgColor);
   border-radius: .5vw;
   cursor: pointer;
   display: flex;
   flex-flow: column;
   height: 95%;
   margin: .35vw 0 0;
   overflow: auto;
   outline: 1px solid lightgrey;
   position: relative;
   width: 40%;
}

#modelBlockEmpty {
   align-items: flex-end;
   background: var(--bgColor);
   border-radius: .5vw;
   cursor: unset;
   display: flex;
   flex-flow: column;
   height: 95%;
   margin: .35vw 0 0;
   overflow: auto;
   outline: 1px solid lightgrey;
   position: relative;
   width: 38%;   
}

.modelBlockItem {
   align-items: flex-end;
   border-radius: .4vw;
   display: flex;
   margin: 2px 10px;
   padding: 0 10px;
   text-transform: uppercase;
   transition: transform .3s;
   width: 94%;
}

@media (hover: hover) and (pointer: fine) {
   .modelBlockItem:hover {
      box-shadow: 0 0 0 1px;   
   }

   .modelActive:hover {
      box-shadow: .2vw .2vw .5vw .1vw grey;
      outline: 2px solid white;
      transform: translateX(-1vw);
   }
}

.modelBlockItem span:first-child {
   width: 90%;
}

@media (max-aspect-ratio: 6/5) {
   #modelBlock {
      border-radius: 2vw;
      height: min-content;
      margin: 6vw 0;
      max-height: 50vw;
      outline-offset: -1px;
      width: 100%;
   }

   #modelBlockEmpty {
      display: none;
   }

   .modelBlockItem {
      margin: 5px 10px;
      text-transform: uppercase;
      width: 97%;
   }

   .modelActive {
      border-radius: 1.5vw;
      box-shadow: 0 0 0 1px gray, inset 0 0 0 2px white,
         inset 0 0 2vw 0vw gray;
      font-weight: bold;
      outline: none;
   }
}