@keyframes mainBg {
   from {
      opacity: 0;
   }
  
   to {
      opacity: 1;
   }
}

@keyframes mainBgClose {
   from {
      opacity: 1;
   }
  
   to {
      opacity: 0;
   }
}

@keyframes mainBgCloseSend {
   0% {
      opacity: 1;
   }

   80% {
      opacity: 1;
   }
  
   100% {
      opacity: 0;
   }
}

.answerNo {
   display: none;
}

.answerOk {
   align-items: center;
   border-radius: 3px;
   display: flex;
   flex-flow: column;
   justify-content: center;
   position: absolute;
   background: white;
   width: 90%;
}

.answerSend {
   composes: answerOk;
   font: 3vw 'main';
   height: 100%;
   z-index: 2;
}

.answerWarning {
   composes: answerOk;
   color: rgb(220, 0, 0);
   font: 2vw 'main';
   height: 10vw;
}

.mainBg {
   align-items: center;
   animation: mainBg .4s;
   backdrop-filter: blur(2px) grayscale(1);
   background: rgb(211, 211, 211, .5);
   display: flex;
   justify-content: center;
   height: 100vh;
   overflow: auto;
   position: fixed;
   width: 100vw;
   z-index: 10; 
}

.mainBgClose {
   composes: mainBg;
   animation: mainBgClose .4s;
   opacity: 0;
}

.mainBgCloseSend {
   composes: mainBg;
   animation: mainBgCloseSend 2s;
   opacity: 0;
}

.main {
   align-items: center;
   background: white;
   border-radius: 2vw 0 2vw 2vw;
   box-shadow: 0 0 2vw grey;
   display: flex;
   flex-flow: column;
   height: 33vw;
   margin: 3.5vw 0 0 0;
   position: relative;
   justify-content: space-between;
   width: 70vw;
}

.h1h2 {
   margin: 2vw 0 0;
}

.h1h2 h1 {
   font: bold 2.5vw 'main';
   text-align: center;
   text-shadow: var(--h2Shadow);
   text-transform: uppercase;
}

.h1h2 h2 {
   font: 1.5vw 'main';
   letter-spacing: .2vw;
   text-align: center;
}

.inputs {
   display: flex;
   flex-flow: column;
   width: 55%;
}

.inputs > input {
   border: none;
   border-bottom: .1vw solid darkslategray;
   margin: 1vw 0 0;
   text-align: center;
}

.sendBlock {
   height: 22%;
   margin: 0 0 2vw;
   width: 55%;
}

.sendBlock div {
   display: flex;
}

.sendBlock span {
   padding: 0 0 0 .7vw;
}

.sendBlock input:first-child {
   height: 1.5vw;
   width: 1.5vw;
}

.inputs > input::placeholder {
   padding: 0 0 0 1vw;
   text-align: left;
}

@media (max-aspect-ratio: 6/5) {   
   .answerSend {
      border-radius: 5vw 0 5vw 5vw;
      font: 6vw 'main';
      width: 100%;
   }
   
   .answerWarning {
      border-radius: 5vw 0 0 0;
      font: 5vw 'main';
      height: 40vw;
      top: 0;
      width: 100%;
   }

   .mainBg {
      background: repeating-linear-gradient(-45deg,
         #0000 0vw 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         repeating-linear-gradient(45deg,
         #0000 0 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         rgb(245 245 245 / 80%);
      box-shadow: inset 0vw 0 30vw 25vw rgb(245 245 245 / 80%);
      height: 100dvh;
      padding: 18vw 0 0;
   }
   
   .main {
      border-radius: 5vw 0 5vw 5vw;
      box-shadow: 0 0 5vw grey;
      height: unset;
      margin: auto;
      width: 90%;
   }
   
   .h1h2 {
      margin: 5vw 0 10vw;
   }
   
   .h1h2 h1 {
      font: bold 7vw 'main';
   }
   
   .h1h2 h2 {
      font: 5vw 'main';
      letter-spacing: .07vw;
      margin: 4vw 4vw 0;
      text-align: center;
   }

   .inputs {
      justify-content: center;
      height: 60%;
      width: 90%;
   }
   
   .inputs > input {
      margin: 5vw 0 0;
      width: 100%;
   }
   
   .inputs > input::placeholder {
      padding: 0 0 0 1vw;
      text-align: left;
   }
   
   .inputs > div {
      margin: 5vw 0 2vw;
      width: 90%;
   }

   .inputs > div span {
      line-height: 5vw;
      width: 55%;
   }

   .inputs > input:last-child {
      margin: 10vw 0;
   }

   .sendBlock {
      height: 30%;
      margin: 10vw 0;
      width: 90%;
   }

   .sendBlock input:first-child {
      height: 8vw;
      margin: 0 5vw 5vw 0;
      width: 8vw;
   }

   .sendBlock > input:last-child {
      background: no-repeat 0% 100%/100% 10%
         linear-gradient(var(--mainBgColor), var(--mainBgColor))
         var(--bgColor);
      border-radius: 3vw;
      box-shadow:
         -.2vw -.2vw darkgrey, .2vw .2vw var(--bgColor), 1vw 1vw 3vw darkgrey;
      height: 10vw;
   }
}