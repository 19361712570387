.filterBlockFilter {
   align-items: center;
   border-radius: 3px;
   display: flex;
   justify-content: space-evenly;
   margin: 1vw 5vw 0;
   outline: 1px solid lightgrey;
}

.filterBlockFilter > div {
   align-items: center;
   display: flex;
   justify-content: center;
   width: 100%;
}

.filterBlockFilter > div > select {
   background: var(--bgColor);
   border: 1px solid lightgray;
   border-radius: .5vw;
   color: inherit;
   margin: .5vw;
   width: 30%;
}
.filterBlockFilter > div > select:change {
   background: var(--mainBgColor);
   color: black;
}

.filterBlockFilter > div > input {
   height: 1.5vw;
   margin: .5vw .5vw .5vw 1.5vw;
   width: 1.5vw;
}

.rankedFilterBlock {
   accent-color: green;
}
.recycleFilterBlock {
   accent-color: red;
}

.reset {
   border-radius: 1vw;
   box-shadow: 0 0 0 1px lightgrey;
   height: 2.1vw;
   line-height: 2.1vw;
   margin: 0 0.5vw 0 0;
   opacity: .5;
   text-align: center;
   transition: box-shadow .5s, opacity .5s;
   width: 40vw;
}

.resetActive {
   box-shadow: 0 0 0 .15vw var(--mainBgColor);
   cursor: pointer;
   opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
   .resetActive:hover {
      text-shadow: 0 0 0 black, 0 0 0 black;
   }
}

.resetActive:active {
   box-shadow: inset 0 0 3px 1px grey;
}

@media (max-aspect-ratio: 6/5) {
   .filterBlockFilter {
      background: rgb(211 211 211 / 40%);
      box-shadow: none;
      flex-flow: wrap;
      margin: 0;
      padding: 3vw 0;
      width: 100%;
   }

   .filterBlockFilter > div {
      width: 50%;
   }

   .filterBlockFilter > div:nth-child(1) {
      width: 47%;
   }

   .filterBlockFilter > div:nth-child(1) select {
      border-radius: 1vw;
      width: 25%;
   }

   .filterBlockFilter > div:nth-child(2) {
      width: 53%;
   }

   .filterBlockFilter > div:nth-child(2) select {
      border-radius: 1vw;
      width: 35%;
   }

   .filterBlockFilter > div:nth-child(3) {
      margin: 5vw 0 0;
      width: 100%;
   }

   .filterBlockFilter > div:nth-child(3) select {
      border-radius: 1vw;
      margin: 0 2vw;
   }

   .filterBlockFilter > div:nth-child(4) {
      border-radius: 1vw;
      margin: 5vw 0 0;
      width: 75%;
   }

   .filterBlockFilter > div:nth-child(4) span:first-of-type {
      order: -1;
   }

   .filterBlockFilter > div > input {
      height: 7vw;
      margin: 0 4vw;
      width: 7vw;
   }

   .reset {
      height: 12vw;
      line-height: 5vw;
      margin: 4vw 3vw 0vw 1vw;
      padding: 0.6vw 0 0;
      width: 20%;
   }

   .resetActive {
      box-shadow: 0 0 0 .5vw var(--mainBgColor);
   }
}