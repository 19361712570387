#angleLines {
   background: linear-gradient(225deg, var(--mainBgColor) 20vw, #0000 60vw);
   height: 100vh;
   position: fixed;
   top: 0;
   right: 0;
   width: 100vw;
   z-index: -1;
}

.divH1H2 {
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   height: calc((100vh - 5vw) * .24);
   position: relative;
   width: 100%;
}

.h1 {
   background: linear-gradient(135deg, #0000 5%,
      rgba(0 0 0 /10%) 5%, rgba(0 0 0 /2%) 6%,
      rgba(0 0 0 /20%) 6%, rgba(0 0 0 /5%) 7%, 
      rgba(0 0 0 /30%) 7%, rgba(0 0 0 /10%) 8%,
      rgba(0 0 0 /45%) 8%, #0000 70%);
   color: white;
   font: bold 2.7vw/1.3 'prosto';
   letter-spacing: .07vw;
   position: relative;
   text-align: center;
   text-shadow: .2vw .2vw .3vw rgb(0, 0, 0);
   text-transform: uppercase;
   width: 100%;
}

.h1::after {
   content: 'с 2016 года';
   font: 1.3vw 'main';
   position: absolute;
   text-shadow: none;
   text-transform: initial;
   top: -1.7vw;
   right: 5.2vw;
}

.h2 {
   font: 1.5vw 'main';
   letter-spacing: .5vw;
   text-align: center;
   width: 100%;
}

.section {
   display: flex;
   flex-flow: wrap;
   min-height: 100vh;
   overflow: hidden;
   padding: 5vw 2vw 0 0;
   position: relative;
   transform-origin: center top;
   transition: opacity .5s;
   width: 100vw;
}

.section1 {
   /* composes: section; */
   min-height: calc(100vh - 3.5vw);
   padding: 0vw 2vw 0 0;
}

.section:nth-of-type(2n) {
   flex-flow: wrap row-reverse;
}

.sectionLast {
   composes: section;
   min-height: calc(100vh - 3vw);
}

.section:last-of-type::after {
   content: none;
}

.section1 .imgWrapper {
   align-items: center;
   display: flex;
   justify-content: center;
   height: calc((100vh - 5vw) * .76);
}

.sectionLast .imgWrapper {
   height: calc(100vh - 8vw);
}

.imgWrapper {
   align-items: center;
   background: url(./img/pointsR.webp) center/contain no-repeat;
   display: flex;
   justify-content: center;
   height: calc(100vh - 5vw);
   position: relative;
   width: 53%;
}

section:nth-of-type(even) .imgWrapper {
   background: url(./img/pointsL.webp) right/contain no-repeat;
}

.img {
   filter:
      contrast(1.3)
      drop-shadow(.5vw .5vw #0000001a)
      drop-shadow(-.5vw -.5vw #0000001a)
      grayscale(1);
   opacity: 1;
   transition: opacity .5s, filter .5s;
   width: 100%;
}

.section1 .img {
   filter:
      contrast(1.3)
      drop-shadow(.5vw .5vw #0000001a)
      drop-shadow(-.5vw -.5vw #0000001a)
      grayscale(0);
   opacity: 1;
   transform: scale(1.35) translate(1.2vw, -2.2vw);
}
.section:nth-of-type(2) .img {
   transform: scale(-1.15, 1.15) translate(1.7vw, -1vw);
}
.section:nth-of-type(3) .img {
   transform: scale(1.2) translate(2vw, -1vw);
}
.section:nth-of-type(4) .img {
   transform: scale(-1.2, 1.2) translate(2vw, -1.5vw);
}
.section:nth-of-type(5) .img {
   transform: scale(1.2) translate(3vw, -.5vw);
}
.section:nth-of-type(6) .img {
   transform: scale(1.2) translate(-4vw, -.5vw);
}
.section:nth-of-type(7) .img {
   transform: scale(1.2) translate(2vw, 0vw);
}
.section:nth-of-type(8) .img {
   transform: scale(1.2) translate(-2vw, -1vw) rotate(-.5deg);
}
.section:nth-of-type(9) .img {
   transform: scale(1.2) translate(1.5vw, -.5vw);
}

.section1 .description {
   height: calc((100vh - 5vw) * .76);
   margin: -2vw 0 0;
}

.sectionLast .description {
   height: calc(100vh - 8vw);
}

.description {
   align-items: center;
   display: flex;
   flex-flow: column;
   font: 1.4vw 'main';
   justify-content: center;
   height: calc(100vh - 5vw);
   width: 47%;
}

.description > h2 {
   color: rgba(0, 0, 0, .8);
   font-size: 2vw;
   text-shadow: var(--h2Shadow);
   text-transform: uppercase;
}
.description > h2:nth-of-type(1) {
   letter-spacing: .01vw;
}

.sectionLast .description a:nth-child(2) {
   color: var(--mainBgColor);
   position: relative;
   text-shadow: 0px 0px 0px red;
}

.sectionLast .description a:nth-child(2)::after {
   background: url(../img/linkWA.webp) no-repeat 0% 0%/100%;
   content: '';
   height: 1.5vw;
   opacity: .7;
   position: absolute;
   right: -2.1vw;
   top: -.1vw;
   transform: scaleY(-1) rotate(40deg);
   width: 2vw;
}

.list {
   display: flex;
   flex-flow: column;
   font-style: italic;
}

.list + span {
   line-height: 3;
}

.buttons {
   display: flex;
   flex-flow: wrap;
   z-index: 1;
}

.link {
   color: inherit;
   text-decoration: none;
}

.middleLine {
   position: relative;
}

.section1 + .middleLine::after {
   bottom: -32vh;
   left: 21vw;
   width: 59vw;
}

.middleLine::after {
   background: url(./img/middleLine.webp) center/contain no-repeat;
   bottom: -30vh;
   content: '';
   height: 50vh;
   left: 20vw;
   opacity: .1;
   position: absolute;
   transform: scaleX(-1);
   width: 50vw;
   z-index: 0;
}

.middleLine:nth-of-type(2n)::after {
   transform: scaleX(1);
}

@media (max-aspect-ratio: 6/5) {
   #angleLines {
      background: linear-gradient(240deg,
         var(--mainBgColor) 30vw, #0000 100vw
      );
      height: 100dvh;
   }

   .h1 {
      background: unset;
      font: bold 10.8vw/1.1 'main';
      margin: 5vw 0 0;
      padding: 0;
      text-shadow:
         0 -0.6vw rgba(0, 0, 0, .4),
         0.6vw -0.6vw rgba(0, 0, 0, .4),
         0.6vw 0 rgba(0, 0, 0, .4),
         0.6vw 0.6vw rgba(0, 0, 0, .4),
         0 0.6vw rgba(0, 0, 0, .4),
         -0.6vw 0.6vw rgba(0, 0, 0, .4),
         -0.6vw 0 rgba(0, 0, 0, .4),
         -0.6vw -0.6vw rgba(0, 0, 0, .4),
         1.2vw 1.2vw 1vw rgba(0, 0, 0, .3);
      width: unset;
   }

   .h1::after {
      color: rgb(47 79 79);
      font: 5vw 'main';
      top: -6vw;
      right: 1.5vw;
   }
   
   .h2 {
      font: 5.5vw 'main';
      letter-spacing: -.17vw;
      margin: 0;
      padding: 0;
   }
   
   .section {
      align-items: center;
      flex-flow: column;
      justify-content: space-evenly;
      height: 100dvh;
      margin: 0;
      padding: 18vw 0 0;
      width: 100%;
   }

   .section4, .section5, .section6, .section7, .sectionLast {
      padding: 18vw 0 5vw;
   }

   .section1 {
      justify-content: space-between;
   }

   .section:nth-of-type(2n) {
      flex-flow: column;
   }

   .sectionLast {
      height: calc(100dvh - 10vw);
      min-height: unset;
   }

   .middleLine::after {
      bottom: -32vw;
      height: 25vh;
      left: 3vw;
      opacity: .2;
      width: 94vw;
   }

   .section1 + .middleLine::after {
      bottom: -18vh;
      left: 3vw;
      width: 94vw;
   }

   .divH1H2 {
      height: unset;
      padding: 2dvh 0 0;
   }

   .section:nth-of-type(3) + .middleLine::after {
      bottom: -18vh;
   }
   .section:nth-of-type(4) + .middleLine::after {
      bottom: -34vw;
   }
   .section:nth-of-type(5) + .middleLine::after {
      bottom: -18vh;
   }
   .section:nth-of-type(6) + .middleLine::after {
      bottom: -34vw;
   }
   .section:nth-of-type(7) + .middleLine::after {
      bottom: -35vw;
   }

   .imgWrapper {
      border-radius: 5vw;
      height: unset !important;
      overflow: hidden;
      outline: 1vw solid var(--bgColor);
      width: 100%;
   }

   .section1 .imgWrapper,
   .section3 .imgWrapper,
   .section5 .imgWrapper,
   .section7 .imgWrapper {
      box-shadow: 0 0 0 1.2vw black, inset 0 0 7vw 2vw black,
         inset -11.2vw 7.2vw 0 var(--mainBgColor), inset -11vw 7vw 0 .5vw black,
         inset -11vw 7vw 0 1vw white, inset -11vw 7vw 0 1.2vw black,
         -12vw 8vw dimgrey, -12vw 8vw 0 0.6vw var(--bgColor),
         -12vw 8vw 0 1vw black;
      margin: 8vw 0 14vw 12vw;
   }
   
   .section2 .imgWrapper,
   .section4 .imgWrapper,
   .section6 .imgWrapper,
   .sectionLast .imgWrapper {
      box-shadow: 0 0 0 1.2vw dimgrey, inset 0 0 7vw 1vw black,
         inset 11.2vw 7.2vw 0 var(--mainBgColor), inset 11vw 7vw 0 0.4vw black,
         inset 11vw 7vw 0 1vw white, inset 11vw 7vw 0 1.2vw dimgrey,
         12vw 8vw dimgrey, 12vw 8vw 0 0.6vw var(--bgColor),
         12vw 8vw 0 0.8vw black;
      margin: 8vw 12vw 14vw 0;
   }

   .section1 .imgWrapper {
      margin: 4vw 0 14vw 12vw;
   }

   .img {
      width: 100%;
   }

   .section1 .img {
      transform: scale(2.6) translate(-5vw, 10vw) rotate(3deg);
   }
   .section:nth-of-type(2) .img {
      transform: scale(-1.6, 1.6) translate(-5vw, 8vw);
   }
   .section:nth-of-type(3) .img {
      transform: scale(-1.8, 1.8) translate(9vw, 0);
   }
   .section:nth-of-type(4) .img {
      transform: scale(1.8) translate(11.2vw, -5.9vw) rotateZ(2deg);
   }
   .section:nth-of-type(5) .img {
      transform: scale(1.7) translate(4vw, -1vw);
   }
   .section:nth-of-type(6) .img {
      transform: scale(-1.8, 1.8) translate(9vw, 4.4vw);
   }
   .section:nth-of-type(7) .img {
      transform: scale(1.8) translate(-3.3vw, 3vw);
   }
   .section:nth-of-type(8) .img {
      transform: scale(1.7) translate(1.1vw, .2vw) rotate(-.7deg);
   }
   
   .section .description {
      height: unset;
   }
   
   .description {
      align-items: unset;
      height: unset;
      width: 100%
   }
   
   .section1 .description h2:nth-child(1),
   .section4 .description h2:nth-child(2) {
      display: none;
   }

   .description h2 {
      font: bold 5.5vw 'main';
      text-align: center;
   }
   
   .description p {
      font: 5vw 'main';
      text-align: center;
   }

   .section3 .description h2:nth-child(1),
   .section4 .description h2:nth-child(1) {
      padding: 0 0 3vw 0;
   }

   .sectionLast .description p {
      line-height: 150%;
      margin: 7vw 0 0 7vw;
      text-align: left;
   }

   .sectionLast .description a:nth-child(2)::after {
      height: 5.5vw;
      opacity: .7;
      right: -9.5vw;
      top: -0.4vw;
      width: 8vw;
   }
   
   .buttons {
      justify-content: space-around;
      margin: 10vw 0;
      padding: 0;
   }

   .link {
      color: inherit;
      text-decoration: none;
      width: 94%;
   }

   .link > div {
      width: 100%;
   }
}