.button {
   align-items: center;
   border-radius: 2vw;
   cursor: pointer;
   display: flex;
   justify-content: center;
   height: 2.4vw;
   outline: .1vw solid lightgrey;
   text-transform: uppercase;
   transition: letter-spacing .3s;
}

:global .buttonActive {
   background: lightgrey;
   box-shadow: .2vw .2vw .5vw grey;
   color: black;
   font-weight: bold;
   outline: .2vw solid white;
}

.brand {
   composes: button;
   text-transform: uppercase;
   width: 32%;
}

.engine {
   composes: button;
   text-decoration: none;
   text-transform: none;
   width: 70%;
}

.brandActive {
   composes: brand;
   background: lightgrey;
   box-shadow: .2vw .2vw .5vw grey;
   color: black;
   font-weight: bold;
   outline: .2vw solid white;
}

.engineActive {
   composes: engine;
   background: lightgrey;
   box-shadow: .2vw .2vw .5vw grey;
   color: black;
   font-weight: bold;
   outline: .2vw solid white;
}

.engineLeft {
   composes: engine;
   background: linear-gradient(90deg,
      lightgrey 20%, #0000 50%);
   box-shadow: .2vw .2vw .5vw grey;
   color: black;
   font-weight: bold;
   outline: .2vw solid white;
}

.engineRight {
   composes: engine;
   background: linear-gradient(90deg,
      #0000 50%, lightgrey 80%);
   box-shadow: .2vw .2vw .5vw grey;
   color: black;
   font-weight: bold;
   outline: .2vw solid white;
}

.home, .callback {
   composes: button;
   background-color: dimgrey;
   background-image:
      linear-gradient(#0000 45%, rgba(0 0 0 /70%) 50%, #0000);
   box-shadow:
      inset 0 0 0 .2vw var(--bgColor), 0 .1vw .7vw rgba(0 0 0 /80%);
   color: white;
   font-size: 105%;
   font-weight: bold;
   height: 3vw;
   letter-spacing: .1vw;
   outline: none;
   text-shadow:         
      1px 1px 1px black,
      1px -1px 1px black,
      -1px -1px 1px black,
      -1px 1px 1px black;
   transition: .3s;
   transform-origin: center top;
   width: 33vw;
}

.callback {
   background-image:
      linear-gradient(#0000 45%, rgba(0 0 0 /35%) 50%, #0000);
   box-shadow:
      inset 0 0 0 .2vw white, 0 .1vw .3vw rgba(0 0 0 /90%);
   margin: 1.5vw 0 0;
   width: unset;
}

:global .modelActive {
   padding: 0 10px 0 30px !important;
   position: sticky;
   top: 15px;
   width: max-content !important;
   width: 45% !important;
}

@media (hover: hover) and (pointer: fine) {
   .button:hover {
      box-shadow: -.1vw -.1vw darkgrey, .2vw .2vw .5vw grey;
      color: black;
      font-size: 1.1em;
      font-weight: bold;
      letter-spacing: .1vw;
      outline: none;
   }

   .buttonActive:hover, .brandActive:hover, .engineActive:hover,
   .engineLeft:hover, .engineRight:hover {
      box-shadow: .2vw .2vw .5vw .1vw grey;
      outline: .2vw solid white;
   }

   .home:hover, .callback:hover {
      background-image:
         linear-gradient(#0000 45%, black 50%, #0000);
      box-shadow: inset 0 0 0 .2vw var(--bgColor), 0 .1vw 1vw black;
      color: white;
      font-size: 1.6vw;
      font-weight: bold;
      letter-spacing: .1vw;
   }

   .callback:hover {
      background-image: linear-gradient(#0000 45%,
         rgba(0 0 0 /50%) 50%, #0000);
      box-shadow: inset 0 0 0 .2vw white, 0 .2vw .7vw rgba(0 0 0 /80%);
   }
}

@media (max-aspect-ratio: 6/5) {
   .button {
      font: 5.5vw 'main';
      height: 11vw;
   }
   
   .brand {
      margin: .7vw 0;
   }
   
   .engine {
      margin: .7vw 0;
      width: 32%;
   }

   .brandActive, .engineActive, .engineLeft, .engineRight {
      box-shadow: 0 0 0 1px gray,
         inset 0 0 0 2px white, inset 0 0 2vw 1vw darkgray;
      font-weight: bold;
      outline: none;
   }

   .engineLeft {
      box-shadow: 0 0 0 1px gray,
         inset 0 0 0 2px white, inset 1vw 0 2vw 0 darkgray;
   }

   .engineRight {
      box-shadow: 0 0 0 1px gray,
         inset 0 0 0 2px white, inset -1vw 0 2vw 0 darkgray;
   }
   
   .home {
      border-radius: 3vw;
      box-shadow:
         -.2vw -.2vw darkgrey, .2vw .2vw var(--bgColor), 0 2vw 5vw dimgrey;
      height: 13vw;
      margin: 0;
      width: 45%;
   }

   div > .home:nth-child(1) {
      width: 94%;
   }

   .callback {
      border-radius: 4vw;
   }
}

