@keyframes imgWrapper {
   from {
      opacity: 0;
   }
  
   to {
      opacity: 1;
   }
}

@keyframes imgWrapperPlate {
   from {
      background: 
         url('./img/logoOnloadImgWrapper.svg') no-repeat center/27%;
   }

   50% { 
      background:
         url('./img/logoOnloadImgWrapper.svg') no-repeat center/30%;
   }
  
   to {
      background:
         url('./img/logoOnloadImgWrapper.svg') no-repeat center/27%;
   }
}

.imgWrapper {
   position: fixed;
   width: 100%;
   z-index: 2;
}

.imgWrapperPlate {
   composes: imgWrapper;
   animation: imgWrapper .4s, imgWrapperPlate 2s infinite;
   left: calc((100vw - min(calc((100vh - 5vw) * 1.333), 80vw)) / 2);
   bottom: calc(((100vh - 5vw) -
      (min(calc((100vh - 5vw) * 1.333), 80vw) / 1.35)) / 2);
   width: min(calc((100vh - 5vw) * 1.333), 80vw);
}

.imgWrapperLot {
   composes: imgWrapper;
   align-items: center;
   animation: imgWrapper .4s;
   backdrop-filter: blur(2px) grayscale(1);
   background: rgba(211, 211, 211, .5);
   display: flex;
   height: 100%;
   padding: 4.5vw 0 0;
   width: 100%;
}

.watermark {
   border-radius: 0 1vw 0 0;
   color: #0000;
   display: flex;
   font-size: 1.2vw;
   justify-content: center;
   height: 2.7vw;
   opacity: 0;
   position: absolute;   
   top: -3vw;
   transition: opacity .1s;
   width: 100%;
}

.watermark p:nth-of-type(odd) {
   display: none;
}

.img {
   border-radius: 0 1vw 0 0;
   box-shadow: 0 0 2vw dimgrey;
   cursor: zoom-out;
   opacity: 0;
   outline: .3vw solid white;
   transition: opacity .3s;
}

.scrlNo {
   display: none;
}
 
.scrllLeft {
   height: calc(100vh - 3vw);
   padding: calc(calc(100vh - 15vw) / 2) 0;
   width: 18vw;
}

.scrlRight {
   height: calc(100vh - 3vw);
   padding: calc(calc(100vh - 15vw) / 2) 0;
   width: 18vw;
}
 
.scrllLeft:active img {
   filter: contrast(2);
   transform: scale(1.2);
}
 
.scrlRight:active img {
   filter: contrast(2);
   transform: scale(1.2);
}
 
.scrllLeft img, .scrlRight img {
   filter: contrast(1.5);
   margin: auto;
   width: 10vw;
}

.wrapNav {
   bottom: 0;
   height: calc(100vh - 6vw);
   position: fixed;
   right: 0;
   width: 6vw;
   z-index: 12;
}

@media (max-aspect-ratio: 6/5) {
   .imgWrapperLot {
      background: repeating-linear-gradient(-45deg,
         #0000 0vw 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         repeating-linear-gradient(45deg,
         #0000 0 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         rgb(245 245 245 / 80%);
      box-shadow: inset 0vw 0 30vw 25vw rgb(245 245 245 / 80%);
      flex-flow: wrap;
      height: 100dvh;
      padding: 18vw 0 0;
      width: 100vw;
      z-index: 13;      
   }

   .imgWrapperLot div:nth-child(2) {
      order: -1;
   }

   .img {
      height: unset;
      width: 100vw;
   }

   .scrllLeft {
      height: unset;
      margin: 0;
      padding: unset;
      width: 50%;
   }
   
   .scrlRight {
      height: unset;
      margin: 0;
      padding: unset;
      width: 50%;
   }

   .scrllLeft img, .scrlRight img {
      width: 70%;
   }
}
