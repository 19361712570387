@keyframes logoStart {
   from {
      transform: rotateZ(0deg);
   }

   to {
      transform: rotateZ(-14deg);
   }
}

@keyframes logoOn {
   from {
      transform: rotateZ(-14deg);
   }

   50% {
      transform: rotateZ(10deg);
   }

   to {
      transform:rotateZ(-14deg);
   }
}

@keyframes flashWA {
   0% {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

.header {
   box-shadow: -81.5vw .1vw 1vw rgba(0 0 0 /80%);
   color: black;
   display: flex;
   position: sticky;
   top: 0;
   width: 100vw;
   z-index: 30;  
}

.logoDiv {
   align-items: center;
   background: linear-gradient(135deg, var(--mainBgColor) 17vw,#0000 17vw);
   cursor: pointer;
   display: flex;
   position: relative;
   transition: transform .1s;
   width: 25vw;
   z-index: 2;
}


.logo {
   background: url('../img/logoBlack_2.1.png') no-repeat center /contain;
   height: 5vw;
   margin: 0 0 0 1.5vw;
   position: relative;
   transform-origin: center bottom;
   width: 5vw;
}

.logoOn {
   composes: logo;
   animation: logoStart .5s forwards, logoOn 1s .5s infinite;
}

@media (hover: hover) and (pointer: fine) {
   .logoDiv:hover div:nth-child(2) {
      transform: scale(1.05);
   }
}

.text p:nth-child(1) {
   color: rgba(0 0 0 /85%);
   font: 2.23vw 'logo';
   text-shadow: 3px 3px 3px rgba(0 0 0 /10%);
}

.text p:nth-child(2) {
   font: 1.2vw/1vw 'main';
   letter-spacing: .134vw;
}

.logoDivAngle {
   box-shadow: -.3vw .2vw 1vw rgba(0 0 0 /60%);
   height: 8vw;
   left: 15vw;
   position: fixed;
   top: -4.7vw;
   transform: rotateZ(-45deg);
   width: 8vw;
   z-index: 1;
}

.header > div:nth-child(3) {
   align-items: center;
   background: linear-gradient(135deg, #0000 3.5vw, var(--mainBgColor) 40vw);
   backdrop-filter: blur(1px);
   -webkit-backdrop-filter: blur(1px);
   box-shadow: 2vw 0.1vw 0.3vw rgba(0 0 0 /30%);
   display: flex;
   font: 1.5vw 'main';
   justify-content: right;
   height: 3.5vw;
   position: absolute;
   right: 0;
   width: 80vw;
}

body .header .headerAuc {
   background: linear-gradient(135deg, #0000 25vw, var(--bgColor) 35vw);
   backdrop-filter: initial;
   -webkit-backdrop-filter: initial;
}

.address {
   display: inline-block;
   position: relative;
}

.address::before {
   background: center/ contain no-repeat url(../img/flag.png);
   content: '';
   filter: drop-shadow(0px 0px 0px black);
   height: 3.5vw;
   left: -4.3vw;
   opacity: .8;
   position: absolute;
   top: -.6vw;
   width: 3.5vw;
}

.menuMob {
   display: none;
}

.phone {
   display: inline-block;
   margin: 0 4vw 0 2vw;
}

.phone a {
   animation: 4s flashWA 2s infinite alternate;
   background: url(../img/iconWA.webp) no-repeat 0% 0%/100%;
   content: '';
   filter: opacity(.7);
   height: 1.5vw;
   opacity: 0;
   position: absolute;
   right: 1.3vw;
   top: 1vw;
   width: 1.5vw;
}

@media (max-aspect-ratio: 6/5) {
   .logoDivAngle, .phone, .address::before, .addressP {
      display: none;
   }

   .header {
      background: linear-gradient(135deg,
         var(--mainBgColor) 5vw, var(--bgColor) 55vw 60vw,
         rgb(0 0 0 /45%) 60vw, #0000 61.5vw
      );
      box-shadow: unset;
      justify-content: space-between;
      height: unset;
      position: fixed;
   }

   .logo {
      height: 18vw;
      width: 15vw;
   }

   .logoDiv {
      background: unset;
      box-shadow: -3.2vw 0 2vw black;
      margin: unset;
      width: 70.7vw;
   }

   .logoDivAngle {
      
   }

   .header > div:nth-child(3) {
      all: unset;
      align-items: center;
      display: flex;
      padding: 0 7vw 0 0;
   }
   
   .text p:nth-child(1) {
      color: rgb(0 0 0 /80%);
      font: 7.25vw/.8 'logo';
      margin: 0;
   }
   
   .text p:nth-child(2) {
      font: 5vw/1 'main';
      letter-spacing: unset;
      margin: 0;
   }
   
   .address {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 10vw;
   }
   
   .menuMob {
      background: rgb(0 0 0 / 75%);
      border-radius: 2vw;
      box-shadow: 0 0 0 .5vw white;
      display: block;
      height: 2vw;
      width: 2vw;
   }
   
   .menuMobOnSpan1 {
      composes: menuMob;
      background: darkred;
      box-shadow: unset;
      height: 0.6vw;
      transform: translate(2.1vw, 4.6vw) rotate(45deg);
      width: 6vw;
   }
   
   .menuMobOnSpan2 {
      display: none;
   }
   
   .menuMobOnSpan3 {
      composes: menuMob;
      background: darkred;
      box-shadow: unset;
      height: .6vw;
      transform: translate(2.1vw, -4.6vw) rotate(-45deg);
      width: 6vw;
   }
}