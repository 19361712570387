.nav {
   display: flex;
   flex-flow: column;
   justify-content: space-evenly;
   height: calc(100vh - 3.5vw);
   position: fixed;
   right: 0;
   top: 3.5vw;
   z-index: 20;
}

.icon {
   height: 1.7vw;
   margin: 0 1.2vw;
   opacity: .3;
   position: relative;
   transition: .1s;
   width: 1.7vw;
}

.icon > img {
   position: relative;
   z-index: 2;
}

.iconActive > img {
   filter: contrast(2);
}

.iconFilter {
   composes: icon;
}

@media (hover: hover) and (pointer: fine) {
   .icon:hover {
      opacity: 1;
      transform: scale(1.8);
   }
}

.icon > div {
   align-items: center;
   background: linear-gradient(90deg,white 60%, rgb(0 0 0 /0));
   border-radius: .4vw 0 0 .4vw;
   display: flex;
   font: .9vw 'main';
   height: 2vw;
   overflow: hidden;
   position: absolute;
   right: -15vw;
   top: -.14vw;
   transition: .05s;
   z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
   .icon:hover > div {
      box-shadow: -.1vw .1vw .3vw dimgrey;
      line-height: 100%;
      padding: 0 2.5vw .1vw .5vw;
      right: -.35vw;
      width: max-content;
   }
}

.iconActive {
   composes: icon;
   opacity: 1;
   transform: scale(1.5);
}

.iconActiveFilter {
   composes: iconActive;
}

@media (max-aspect-ratio: 6/5) {
   .nav {
      align-items: flex-end;
      background: repeating-linear-gradient(-45deg,
         #0000 0vw 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         repeating-linear-gradient(45deg,
         #0000 0 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         rgb(245 245 245 / 80%);
      box-shadow:
         inset 0vw 0 30vw 25vw rgb(245 245 245 / 80%);
      height: 100dvh;
      padding: 18vw 0 0;
      right: -110vw;
      transition: right .1s;
      top: 0;
      width: 100vw;
   }
   
   .navMob {
      composes: nav;
      backdrop-filter: blur(2px) grayscale(1);
      background: repeating-linear-gradient(-45deg,
         #0000 0vw 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
         repeating-linear-gradient(45deg, #0000 0 2.2vw,
         rgb(246 188 0 / 50%) 2.6vw),
         rgb(245 245 245 / 80%);
      box-shadow: inset 0vw 0 30vw 25vw rgb(245 245 245 / 80%);
      height: 100dvh;
      padding: 18vw 0 0;
      right: 0;
      top: 0;
   }

   .icon {
      border-radius: 3vw;
      height: 10vw;
      margin: 0 5vw;
      opacity: 1;
      width: 10vw;
   }

   .iconFilter {
      margin: -30vw 4vw;
   }

   .icon img {
      opacity: .5;
   }

   .icon > div {
      background: unset;
      font: bold 7vw 'main';
      height: auto;
      margin: 0 30vw 0 0;
      opacity: .6;
      text-align: right;
      width: max-content;
   }

   .icon .statName {
      line-height: 1em;
      top: -3vw;
   }

   .iconActive {
      opacity: 1;
      transform: unset;
   }

   .iconActive img {
      opacity: 1;
      transform: scale(1.4);
   }

   .iconActiveFilter {
      margin: -30vw 4vw;
   }

   .iconActive > div {
      border-bottom: 1px solid;
      border-radius: unset;
      color: black;
      opacity: 1;
      text-transform: capitalize;
      width: 45vw;
   }
}