.close {
   height: 2.3vw;
   position: absolute;
   right: .9vw;
   top: 1vw;
   width: 2.3vw;
   z-index: 25;
}

.callback {
   composes: close;
}

.calc {
   composes: close;
}

.lot {
   composes: close;
   background: url(../img/goback.png) no-repeat center/cover;
   position: fixed;
   right: .9vw;
   top: 6vw;
   transition: transform .2s, filter .2s;
   transform: scale(.9);
}

.lot span {
   display: none;
}

@media (hover: hover) and (pointer: fine) {
   .close:hover span {
      background: red;
   }

   .close:hover .span1 {
      transform: translateY(1.5vw) rotate(135deg);
   }

   .close:hover .span2 {
      transform: translateY(1.5vw) rotate(-135deg);
   }

   .lot {
      filter: contrast(.5);
   }

   .lot:hover {
      filter: contrast(1);
      transform: scale(1.1);
   }
}

.span {
   background: darkslategray;
   height: 1px;
   position: absolute;
   transition: transform 1s;
   width: 100%;   
}

.span1 {
   composes: span;
   transform: translateY(1.5vw) rotate(45deg);
}

.span2 {
   composes: span;
   transform: translateY(1.5vw) rotate(-45deg);
}

@media (max-aspect-ratio: 6/5) {
   .close {      
      height: 10vw;
      width: 10vw;
   }

   .calc {
      right: 4.5vw;
      top: 4vw;      
   }

   .callback {
      right: 2vw;
      top: 2vw;
   }

   .span1, .span2 {
      top: 3.3vw;
   }

   .lot {
      background: center /contain no-repeat url(../img/iconWA.webp);
      filter: 
              drop-shadow(2px 0 0 #fff)
              drop-shadow(0 2px 0 #fff)
              drop-shadow(-2px 0 0 #fff)
              drop-shadow(0 -2px 0 #fff);
      right: 4vw;
      top: 25vw;
      z-index: 1;
   }
}