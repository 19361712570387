#findLotNum {
   align-items: center;
   display: flex;
   font: 1.3vw 'main';
   justify-content: flex-end;
   padding: .4vw 5vw .4vw 0;
   position: absolute;
   top: .4vw;
   width: 100vw;
   text-transform: lowercase;
}

#findLotNum input[type='tel'] {
   background: #0000;
   border: 1px solid rgb(0 0 0 /35%);
   border-radius: .5vw;
   margin: 0 0 0 1vw;
   text-align: center;
   width: 6vw;
}

#findLotNum input[type='tel']:focus-visible {
   outline: none;
}

#findLotNum input[type='submit'] {
   background: #0000;
   border: none;
   opacity: 0;
   padding: 0;
   transition: .5s;
   width: 0;
}

#findLotNum input[type='tel']:focus-visible + input {
   background: center/contain no-repeat url(../../img/search.webp);
   cursor: pointer;
   margin: 0 0 0 .5vw;
   opacity: .6;
   padding: 1px;
   width: 4.5vw;
}

@media (max-aspect-ratio: 6/5) {
   #findLotNum {
      font-size: 4vw;
      justify-content: center;
      padding: 0;
      position: relative;
      width: unset;
   }

   #findLotNum p {
      display: none;
   }

   #findLotNum input {
      border-radius: 3vw !important;
   }

   #findLotNum input[type='tel'] {
      margin: 0 2vw 0 7vw;
      width: 20vw;
   }
   
   #findLotNum input[type='tel']:focus-visible {
      background: unset;
   }
   
   #findLotNum input[type='submit'] {
      background: right/contain no-repeat url(../../img/search.webp);
      height: 10vw;
      margin: 0;
      opacity: .7;
      padding: 0;
      transition: usnet;
      width: 5vw;
   }

   #findLotNum input[type='tel']:focus-visible + input {
      width: 5vw;
      margin: 0;
      opacity: 0.7;
      padding: 0;
   }
}