.brands {
   align-items: center;
   display: flex;
   flex-flow: wrap;
   justify-content: space-between;
   width: 40%;
}

@media (max-aspect-ratio: 6/5) {
   .brands {
      height: min-content;
      width: 100%;
   }
}