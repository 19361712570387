#lotCard {
   align-items: center;
   display: flex;
   flex-flow: column;
   margin: 0 5vw 2vw;
}

#h1AndDateUpdate {
   align-items: baseline;
   background: url(../../../../public/background.png),
      linear-gradient(135deg, #0000 17.5vw, rgb(245 245 245 / 70%) 18vw);
   backdrop-filter: blur(1px);
   -webkit-backdrop-filter: blur(1px);
   display: flex;
   justify-content: center;
   margin: 3vw 0 2vw;
   position: sticky;
   top: 0;
   width: 100vw;
   z-index: 1;
}

#h1AndDateUpdate h1 {
   font: bold 2.5vw 'main';
   padding: 0 2vw 0 0;
   text-transform: uppercase;
}

#lotCardMainInfo::before {
   background: linear-gradient(175deg, var(--mainBgColor) 3vw, #0000 6vw),
   linear-gradient(235deg, var(--bgColor) .5vw, #0000 6vw),
   linear-gradient(315deg,
   var(--mainBgColor) 9vw 9.5vw,
   var(--bgColor) 9.5vw 9.9vw,
   var(--mainBgColor) 9.9vw 10.2vw,
   #0000 10.2vw
   ),
   linear-gradient(355deg, var(--mainBgColor) 5vw, #0000 6vw);
   border-radius: .7vw;
   content: '';
   height: 7.85vw;
   left: -0.5vw;
   position: absolute;
   top: -0.2vw;
   width: 90.8vw;
   z-index: -1;
}

#lotCardMainInfo {
   background: linear-gradient(315deg, #0000 9.9vw, var(--bgColor) 9.9vw);
   border-radius: 1vw;
   display: flex;
   justify-content: space-between;
   height: 7.5vw;
   position: relative;
   width: 100%;
}

#lcmiImg {
   width: 11.2%;
}

.itemDiv {
   display: flex;
   flex-flow: column;
   justify-content: space-evenly;
}

.itemDivImg {
   background:
   url('../../../img/logoFiller.svg')
   no-repeat 80%/50%;
   position: relative;
}

.itemDivImg img {
   border-radius: 1vw 0 0 1vw;
   margin: 0 .2vw 0 .2vw;
}

.itemDivLot {
   padding: 0 0 0 1vw;
   position: relative;
   width: 17%;
}

.itemDivMarka {
   width: 25%;
}

.itemDivMarka > p:nth-child(2) {
   font-weight: bold;
}

.itemDivYear {
   width: 15%;
}

.itemDivCost {
   width: 15%;
}

.itemDivYear .itemDivCost {
   width: 20%;
}

.itemDivRating {
   align-items: center;
   font-weight: bold;
   justify-content: center;
   padding: 0;
   position: relative;
   width: 7%;
}

#descriptionCost {
   margin: 3vw 0;
   text-align: center;
}

#lotCardDetails {
   align-items: center;
   display: flex;
   flex-flow: column;
   width: 100%;
}

#lcdRating {
   text-align: center;
   width: 89.6vw;
}

#descriptionRecycle {
   display: flex;
   flex-flow: wrap;
   justify-content: space-evenly;
   margin: 0;
   width: 100%;
}

#descriptionRating {
   border-radius: .5vw;
   border-radius: .5vw;
   display: flex;
   justify-content: space-around;
   outline: 1px solid lightgrey;
   padding: .2vw 0;
   text-transform: capitalize;
}

#lcdComments {
   display: flex;
   justify-content: space-around;
   margin: 3vw 0 0;
   width: 100%;
}

#lcdComments > div {
   text-align: center;
   width: 47%;
}

#descriptionComment {
   display: flex;
   justify-content: space-evenly;
   padding: 2vw 0 0;
}

#descriptionRecycle > div {
   width: 45%;
}

.lcdMainImgs {
   display: flex;
   flex-flow: wrap;
   justify-content: space-around;
   padding: 2vw 0 0;
   width: 100%;
}

.mainImgsWrap {
   border-radius: .4vw;
   margin: .25vw 0 0;
   overflow: hidden;
   width: 17.7vw;
}

.mainImgsWrapRecycle {
   border: 1px solid white;
   border-radius: .4vw;
   overflow: hidden;
   width: 16.66%;
}

.lcdMainImgs img {
   cursor: zoom-in;
   height: 0px;
   opacity: 0;
   transition: .3s;
   width: 100%;
}

@media (hover: hover) and (pointer: fine) {
   .lcdMainImgs img:hover {
      border-radius: 1.5vw;
      box-shadow: inset 0 0 0 1vw red; 
      filter: brightness(1.1);
      transition: transform .3s, border-radius .2s;
      outline: .2vw solid var(--mainBgColor);
      outline-offset: -1vw;
      transform: scale(1.05);
   }
}

.lcdMainImgs div[data-video1],
.lcdMainImgs div[data-video2] {
   cursor: initial;
   text-align: center;
   width: 20%;
}

.lcdMainImgs div[data-video1] span,
.lcdMainImgs div[data-video2] span {
   font-size: 0;
}

.lcdMainImgs div video {
   cursor: initial;
   height: 0px;
   opacity: 0;
   transition: .3s;
   width: 96%;
}

#lcdInfoRate {
   padding: 0 5px;
   width: 100%;
}

.lcdRatingPcs  span {
   font-weight: bold;
}

.lcdInfoRateEmpty {
   display: flex;
   flex-flow: wrap;
   justify-content: space-between;
   padding: 0 5px;
   width: 100%;
}

.lcdirDiv {
   padding: 2vw 0;
}

.lcdirDivEmpty {
   padding: 2vw 0;
   width: 49%;
}

.lcdirdName {
   background: linear-gradient(transparent 40%, var(--bgColor));
   border-radius: 3px 3px 0 0;
   border-bottom: 1px solid lightgray;
   font-weight: bold;
   padding: 0 0 0 5px;
}

.lcdirdComments {
   display: inline-block;
   width: 50%;
}

.lcdirdCommentsEmpty {
   display: inline-block;
   width: 100%;
}

.lcdirdComments > div {
   border-bottom: 1px solid rgb(240 240 240);
   display: flex;
   width: 100%;
}

.lcdirdComments > div > p:first-child {
   background: var(--bgColor);
   overflow-wrap: anywhere;
   padding: 0 0 0 1vw;
   width: 30%;
}

.lcdirdComments > div > p:nth-child(2) {
   overflow-wrap: anywhere;
   padding: 0 0 0 1vw;
   width: 70%;
}

.lcdirdImgs {
   display: inline-block;
   vertical-align: top;
   width: 50%;
}

.infoImgsWrap {
   border-left: 2px solid white;
   border-right: 2px solid white;
   display: inline-block;
   overflow: hidden;
   width: 25%;
}

.infoImgsWrap img {
   cursor: zoom-in;
   height: 0;
   opacity: 0;
   transition: .3s;
}

@media (hover: hover) and (pointer: fine) {
   .infoImgsWrap img:hover {
      filter: brightness(1.1);
      outline: .16vw solid var(--mainBgColor);
      outline-offset: -.7vw;
      transition: transform .3s;
      transform: scale(1.05);
   }
}

@media (max-aspect-ratio: 6/5) {
   #lotCard {
      margin: 0;
      padding: 1vw;
      width: 100vw;
   }

   #lotCard #h1AndDateUpdate {
      align-items: center;
      background: unset;
      backdrop-filter: unset;
      -webkit-backdrop-filter: unset;
      flex-flow: column;
      margin: 20vw 0 0;
      position: relative;
   }

   #lotCard #h1AndDateUpdate H1 {
      font: bold 6vw 'main';
      padding: 3vw 0 0;
   }

   #lotCard #lotCardMainInfo {
      background: unset;
      box-shadow: none;
      flex-flow: wrap;
      height: auto;
      margin: 5vw 0;
      outline: none;
   }

   #lotCardMainInfo::before {
      content: none;
   }

   #lotCard #lcmiImg {
      display: none;
   }

   #lotCard .itemDivLot {
      border: unset;
      flex-flow: unset;
      justify-content: space-between;
      padding: 8vw 0 1vw;
      order: 1;
      text-align: center;
      width: 100%;
   }

   #lotCard .itemDivLot p:nth-child(1) {
      margin: 0 0 0 3vw;
   }

   #lotCard .itemDivLot p:nth-child(2) {
      margin: 0 3vw 0 0;
   }      

   #lotCard .itemDivMarka {
      border-radius: 3vw;
      border-top: 1vw solid rgb(248 204 55);
      box-shadow: 0 2vw 3vw darkgrey;
      margin: auto;
      order: 2;
      padding: 5vw 0 2vw;
      text-align: center;
      width: 96%;
   }

   #lotCard .itemDivMarka > p:nth-child(1) {
      font: bold 6vw 'main';
   }
   
   #lotCard .itemDivMarka > p:nth-child(2) {
      font-weight: unset;
   }

   #lotCard .itemDivYear {
      border: unset;
      flex-flow: row;
      justify-content: space-around;
      margin: auto;
      order: 3;
      padding: 3vw 0;
      text-align: center;
      width: 96%;
   }

   #lotCard .itemDivYear > p {
      width: 25%;
   }

   #lotCard .itemDivYear > p:nth-child(1) {
      width: 18%;
   }

   #lotCard .itemDivYear > p:nth-child(4) {
      width: 32%;
   }
   
   #lotCard .itemDivCost {
      align-items: baseline;
      border-radius: 3vw;
      box-shadow: 0 0 0 0.1vw darkgrey;
      flex-flow: row wrap;
      margin: 0 auto;
      order: 5;
      padding: 5vw 0;
      text-align: center;
      width: 96%;
   }

   #lotCard .itemDivCost p:nth-child(1) {
      width: 28%;
   }

   #lotCard .itemDivCost p:nth-child(2) {
      font: bold 6vw 'main';
      width: 44%;
   }

   #lotCard .itemDivCost p:nth-child(3) {
      width: 28%;
   }

   .lcdInfoRateEmpty {
      width: 98%;
   }

   #lotCard .itemDivRating {
      border: unset;
      margin: 3vw 0 0;
      order: 4;
      position: absolute;
      width: 98vw;
   }

   .lcdirDivEmpty {
      width: 100%;
   }

   #lotCard .itemDivRating p:nth-child(1) {
      display: none;
   }
   
   #lotCard .itemDivRating p:nth-child(2) {
      margin: 0 0 1vw;
   }

   #lotCard .itemDivRating p:nth-child(3) {
      background:
         linear-gradient(225deg, rgb(254 245 206) 20%, rgb(246 188 0));
      border-radius: 3vw;
      box-shadow: 0 0 1px 3px rgb(254 252 250);
      font: bold 7vw 'main';
      height: 10vw;
      line-height: 9.9vw;
      text-align: center;
      width: 13vw;
   }

   #lotCard .itemDivRating p[data-norate] {
      color: red;
      display: unset;
      font-weight: bold;
      margin: -5vw 0 0;
      text-align: center;
      width: 96%;
   }

   #lotCard #descriptionCost {
      margin: 0 auto;
      padding: 0 0 10vw;
      width: 96%;
   }

   #lotCard #descriptionCost p {
      padding: 2vw 0 0;
   }

   #lotCard #lotCardDetails {
      border: unset;
      padding: unset;
   }

   #lotCard #lcdRating {
      border-top: 1px solid darkgrey;
      padding: 2vw 0;
      width: 96%;
   }

   #lotCard #descriptionRating {
      background: unset;
      justify-content: space-between;
      outline: none;
   }

   #lotCard .lcdMainImgs {
      padding: 0;
   }

   .mainImgsWrap {
      margin: 1vw 0;
      width: 98%;
   }

   .mainImgsWrapRecycle {
      width: 98%;
   }

   #lotCard .lcdMainImgs div[data-video2] {
      display: none;
   }

   #lotCard .lcdMainImgs div[data-video1] {
      margin: 5vw 0 0;
      order: 10;
      width: 99%;
   }

   .lcdMainImgs div video {
      height: unset;
      width: 98%;
   }

   #lotCard #lcdComments {
      align-items: center;
      flex-flow: column;
      margin: 5vw 0;
      width: 96%;
   }

   #lotCard #lcdComments > div {
      text-align: right;
      width: 97%;
   }

   #lotCard #lcdComments > div span:first-child {
      display: block;
      text-align: left;
   }

   #lotCard #lcdInfoRate {
      margin: 5vw 0 0;
      padding: 0;
      width: 96%;
   }

   #lotCard .lcdirDiv {
      padding: 5vw 0;
   }

   #lotCard .lcdirdName {
      font: bold 5vw 'main';
      letter-spacing: 2px;
   }

   #lotCard .lcdirdComments {
      width: 100%;
   }

   .lcdirdComments > div > p:first-child {
      padding: 1vw 0 1vw 3vw;
      width: 45%;
   }

   .lcdirdImgs {
      width: 100%;
   }

   .infoImgsWrap {
      width: 50%;
   }
}