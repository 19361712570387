#selectBlock {
   display: flex;
   justify-content: space-between;
   height: 16vw;
   padding: 0 5vw 0;
   position: relative;
}

@media (max-aspect-ratio: 6/5) {
   #selectBlock {
      flex-flow: wrap;
      height: 100%;
      margin: 10vw 0 0;
      width: 100%;
   }    
}