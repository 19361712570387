.filter {
   align-items: center;
   display: flex;
   justify-content: center;
}

.title {
   background: url(../../public/background.png),
      linear-gradient(135deg, #0000 17.5vw, rgb(245 245 245 / 60%) 18vw);
   backdrop-filter: blur(1px);
   -webkit-backdrop-filter: blur(1px);
   font: bold 2.8vw 'main';
   margin: 2vw 0 1vw;
   position: sticky;
   text-align: center;
   text-transform: uppercase;
   top: -.3vw;
   z-index: 1;
}

.title::after {
   background: linear-gradient(90deg, #0000, var(--mainBgColor), #0000);
   bottom: 0;
   content: '';
   height: .3vw;
   left: 26vw;
   position: absolute;
   width: 48vw;
}

.title2 {
   font: 1.3vw 'main';
   letter-spacing: .2vw;
   margin: 0 0 3vw;
   text-align: center;
   text-transform: lowercase;
}

@media (max-aspect-ratio: 6/5) {
   .title {
      display: flex;
      font: bold 8vw 'main';
      justify-content: center;
      margin: 0;
      padding: 22vw 0 2vw;
      position: relative;
   }
   
   .title::after {
      content: none;
   }
   
   .title2 {
      font: 5vw 'main';
      letter-spacing: 0;
      margin: 0;
   }
}