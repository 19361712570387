#infoBlock {
   margin: 3vw 5vw 0;
}

#statInfo {
   display: flex;
   flex-flow: wrap;
   margin: 0 0 3vw 0;
}

#statInfo > p:nth-child(2) {
   margin: 2vw 3vw 0 0;
   text-align: right;
   width: 100%;
}

#statInfoDescription {
   margin: .5vw 0 0;
   text-align: center;
   width: 100%;
}

.statInfoDifferent {
   align-items: center;
   background: linear-gradient(135deg, var(--mainBgColor) 1vw, #0000 1.1vw);
   border-radius: 0 .5vw .5vw .5vw;
   box-shadow: inset 0 0 0 1px var(--mainBgColor);
   display: flex;
   justify-content: center;
   margin: .5vw 3vw;
   padding: .5vw;
   width: 100%;
}

.sidDetail {
   display: flex;
   flex-flow: column;
   width: 70%;
}

.siddCoast {
   display: flex;
   justify-content: space-evenly;
   width: 100%;
}

.siddCoast > p:first-child {
   padding: 0 0 0 8%;
}

.siddCoast > p {
   padding: 0 0 0 4%;
   width: 25%;
}

#mileageInfo {
   align-items: center;
   display: flex;
   justify-content: space-around;
   width: 100%;
}

#mileageInfo span:nth-child(3) {
   font-weight: bold;
}

@media (max-aspect-ratio: 6/5) {
   #infoBlock {
      margin: 6vw 0 0;
      width: 100%;
   }

   #mileageInfo {
      flex-flow: wrap;
      padding: 0;
   }

   #mileageInfo > div:nth-child(1) {
      display: none;
   }

   #mileageInfo > span:nth-child(2) {
      text-align: center;
      width: 100%;
   }

   #mileageInfo > span:nth-child(3) {
      font-weight: unset;
      text-align: center;
      width: 100%;
   }

   #mileageInfo > span:nth-child(4) {
      font-weight: bold;
      margin: 2vw auto;
      text-align: center;
   }

   #statInfo {
      margin: 0 0 5vw;
   }

   #statInfoDescription span {
      display: block;
   }

   #statInfo > p:nth-child(2) {
      margin: 5vw auto;
      text-align: center;
      width: 75.4%;
   }

   .statInfoDifferent {
      background: linear-gradient(135deg, var(--mainBgColor) 5vw, #0000 0vw);
      border-radius: 1vw 3vw 3vw 3vw;
      flex-flow: wrap;
      margin: 0 2vw 5vw;
      padding: 0;
   }

   .statInfoDifferent > p {
      border-bottom: 1px dotted var(--mainBgColor);
      padding: 0 5vw;
   }

   .sidDetail {
      width: 100%;
   }

   .sidDetail > div {
      padding: 0 0 2vw;
   }

   .sidDetail > div:not(:last-child) {
      box-shadow: 0 1px 0 lightgray;
   }

   .siddCoast {
      flex-flow: wrap;
      justify-content: space-between;
   }

   .siddCoast > p {
      width: 33%;
   }
   
   .siddCoast > p:first-child {
      font-weight: bold;
      margin: 0 0 -2vw;
      padding: 2vw;
      width: 100%;
   }

}