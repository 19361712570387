@keyframes main {
   from {
      opacity: 0;
   }
  
   to {
      opacity: 1;
   }
}

@keyframes mainClose {
   from {
      opacity: 1;
   }
  
   to {
      opacity: 0;
   }
}

.main {
   align-items: center;
   animation: main .5s;
   backdrop-filter: blur(2px) grayscale(1);
   background: rgba(211, 211, 211, .5);
   display: flex;
   justify-content: center;
   height: 100vh;
   position: fixed;
   width: 100vw;
   z-index: 10;
}

.mainClose {
   composes: main;
   animation: mainClose .5s;
   opacity: 0;
}

.bg {
   background: white;
   border-radius: 2vw 0 2vw 2vw;
   box-shadow: 0 0 2vw grey;
   height: 33vw;
   margin: 3.5vw 0 0 0;
   position: relative;
   width: 70vw;
}

@media (max-aspect-ratio: 6/5) {
   .main {      
      background: unset;
      height: 100dvh;
   }
   
   .bg {
      background: repeating-linear-gradient(-45deg,
        #0000 0vw 2.2vw, rgb(246 188 0 / 50%) 2.6vw),
        repeating-linear-gradient(45deg, #0000 0 2.2vw,
        rgb(246 188 0 / 50%) 2.6vw), rgb(245 245 245 / 80%);
      box-shadow: inset 0vw 0 30vw 25vw rgb(245 245 245 / 80%);
      display: flex;
      flex-flow: column;
      height: 100dvh;
      margin: 0;
      padding: 18vw 0 0;
      width: 100vw;
   }
}