.footer {
   align-items: center;
   display: flex;
   justify-content: space-evenly;
   height: 3vw;
}

.footer a {
   color: inherit;
   text-decoration: none;
}

@media (max-aspect-ratio: 6/5) {
   .footer {
      align-items: flex-start;
      flex-direction: column;
      font-size: 70%;
      height: 10vw;
      padding: 0 0 0 7vw;
      text-align: right;
   }
}