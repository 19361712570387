.resultPlate {
   display: flex;
   flex-flow: wrap;
   margin: 0 5vw 0;
}

.resultItemPlate {
   border-radius: 5px;
   display: flex;
   flex-flow: wrap;
   font-size: 1.1vw;
   justify-content: space-between;
   min-height: 17vw;
   margin: .7vw 0 .7vw .7vw;
   outline: 1px solid lightgray;
   position: relative;
   transform-origin: 0% 0%;
   transition: box-shadow .2s;
   width: 14.4vw;
}

.resultItemPlate:nth-child(6n+1) {
   margin: .7vw 0;
}

.noResult {
   align-items: center;
   border: 1px solid darkslategrey;
   font: 150% 'main';
   justify-content: center;
}

@media (hover: hover) and (pointer: fine) {
   .resultItemPlate:hover {
      box-shadow: 0 .7vw .5vw grey;
      color: black;
      outline: 1px solid darkgray;
   }
}

.itemDivPlate {
   display: flex;
   flex-flow: nowrap;
   justify-content: space-between;
   padding: 0 .5vw;
   width: 100%;
}

.itemImgPlate {
   composes: itemDivPlate;
   background:
      url('../../img/logoFiller.svg')
      no-repeat 50% 70%/40%;
   border: none;
   border-radius: 5px 5px 0 0;
   cursor: no-drop;
   height: 10.1vw;
   overflow: hidden;
   padding: 0;
}

.imgOk {
   cursor: zoom-in;
   margin: -.7vw 0 0;
   transition: .2s linear;
}

@media (hover: hover) and (pointer: fine) {
   .imgOk:hover {
      filter: contrast(1.15);
      transform: scale(1.05);
   }
}

.itemLotPlate {
   composes: itemDivPlate;
   flex-flow: nowrap;
   justify-content: space-between;
}

.itemLotPlate p {
   padding: 0;
}

.itemLotPlate p:nth-child(2) {
   display: none;
}
.itemLotPlate p:nth-child(2) {
   display: none;
}

.itemMarkaPlate {
   composes: itemDivPlate;
   align-items: center;
   background: rgb(0 0 0 /5%);
   border-radius: 3px;
   justify-content: space-evenly;
   padding: 0;
   transition: .1s;
}

.itemMarkaPlateHover {
   background: linear-gradient(white 25%, rgb(246 188 0 /80%)) white;
   border-radius: 5px;
   box-shadow: 0 0 0 .05vw darkgrey;
   transform: scale(1.03);
}

.itemMarkaPlate p:nth-child(1) {
   text-transform: capitalize;
}

.itemMarkaPlate p:nth-child(2) {
   font-weight: bold;
   text-align: center;
   text-transform: uppercase;
}

.itemMarkaPlate p:nth-child(3), .markaOther {
   display: none;
}

.itemYearPlate {
   composes: itemDivPlate;
   align-items: center;
   word-spacing: -0.1vw;
}

.itemCostPlate {
   composes: itemDivPlate;
   align-items: center;
   word-spacing: -0.1vw;
}

.itemCostPlate p:nth-child(1) {
   font-size: 80%;
}

.itemRatingPlate {
   composes: itemDivPlate;
   background: rgb(245, 245, 245, .5);
   border-radius: 0 5px 0 8px;
   color: black;
   outline: 1px solid lightgrey;
   padding: 0px 10px;
   position: absolute;
   right: 0;
   width: auto;
}

.itemRatingPlate p:nth-child(2) {
   margin: 0 0 0 .5vw;
}

.ratingPlate {
   font-weight: bold;
}

.itemDivLotPlate {
   flex-flow: nowrap;
   margin: 0 3vw;
   padding: 0;
   text-align: center;
   width: 79%;
}

.itemDivLotPlate p {
   width: 50%;
}

.plugOnClickPlate {
   bottom: 0;
   cursor: pointer;
   height: 8vw;
   position: absolute;
   width: 100%;
}

:global .arrowUp {
   bottom: 2.5vw;
   display: none;
   filter: drop-shadow(0 0 .3vw dimgrey);
   height: 4vw;
   position: fixed;
   right: 48vw;
   width: 4vw;
}

@media (hover: hover) and (pointer: fine) {
   :global .arrowUp:hover {
      transform: scale(1.2);
   }
}
 
:global .arrowUp span {
   background: var(--mainBgColor);
   height: .5vw;
   position: absolute;
   top: 60%;
   width: 63%;
}
 
:global .arrowUp span:first-child {  
   border-radius: 5px 0;
   left: 39%;
   transform: rotate(35deg);  
}
 
:global .arrowUp span:last-child {
   border-radius: 0 5px;  
   left: -6%;  
   transform: rotate(-35deg);
}
 
:global .arrowUpActive {
   display: block;
}

@media (max-aspect-ratio: 6/5) {
   .resultPlate {
      justify-content: center;
      margin: 0;
      padding: 0 0 12vw;
      width: 100%;
   }

   .resultItemPlate:nth-child(4n+2) {
      margin: 3vw 0;
   }
   
   .resultItemPlate:nth-child(4n+3) {
      margin: 3vw 0;
   }

   .resultItemPlate {
      background: white;
      border-radius: 5px;
      font-size: unset;
      justify-content: space-between;
      height: auto;
      margin: 3vw 0;
      outline: 1px solid grey;
      width: 93vw;
   }

   .itemDivPlate p:nth-child(1) {
      text-align: left;
      width: 30%;
   }

   .itemDivPlate p:nth-child(2) {
      text-align: center;
      width: 39%;
   }

   .itemDivPlate p:nth-child(3) {
      text-align: right;
      width: 31%;
   }

   .itemMarkaPlate {
      background: linear-gradient(315deg,
         var(--bgColor) 5vw, var(--mainBgColor) 20vw, white 20vw 20.5vw,
         var(--mainBgColor) 20.5vw 21vw, var(--bgColor) 21vw);
      border: none;
      border-radius: 1vw;
      justify-content: start;
      left: -1.5%;
      outline: 1px solid grey;
      padding: 1vw;
      position: absolute;
      width: 103%;
   }

   .itemMarkaPlate p {
      margin: 0 1vw;
      width: unset !important;
   }

   .itemLotPlate p:nth-child(1) {
      font-weight: bold;
      padding: 0;
      position: absolute;
      right: 1vw;
      top: 1vw;
      width: auto;
      z-index: 1;
   }

   .itemImgPlate {
      height: 71vw;
      padding: 0;
      transition: height .3s;
      width: 93vw;
   }

   .imgNo {
      height: 14vw;
   }

   .itemRatingPlate {
      background: white;
      border-radius: unset;
      color: unset;
      justify-content: start;
      left: 0;
      outline: unset;
      padding: 1vw;
      position: absolute;
      top: 66vw;
      width: 37.5vw;
   }

   .itemRatingPlateImgNo {
      top: 9vw;
   }

   .itemRatingPlate p {
      width: auto !important;
   }

   .itemRatingPlate p:nth-child(2) {
      margin: 0 0 0 1vw;
   }
   
   .itemLotPlate {
      align-items: center;
      background: white;
      border-bottom: unset;
      flex-flow: wrap;
      justify-content: space-between;
      margin: -5vw 0 0 37vw;
      padding: 0 1vw;
      width: 100%;
   }

   .itemLotPlate p:nth-child(2) {
      display: block;
      font-weight: bold;
      margin: 1vw 0;
      width: auto;
   }

   .itemLotPlate p:nth-child(3) {
      width: auto;
   }

   .itemYearPlate {
      background: white;
      border-bottom: 1px dashed lightgray;
      border-top: 1px dashed lightgray;
      padding: 1vw;
   }

   .itemCostPlate {
      background: white;
      padding: 1vw;
   }

   .plugOnClickPlate {
      height: 93vw;
   }

   .plugOnClickPlateImgNo {
      height: 36vw;
   }

   :global .arrowUp {
      height: 15vw;
      right: 42.5vw;
      width: 15vw;
   }

   :global .arrowUpActive {
      display: block;
      filter: drop-shadow(0 0 2vw dimgrey);
   }
   
   :global .arrowUp span {
      height: 1.5vw;
   }
}
